import loadPolyfills from '../brighteon/load_polyfills';
import { start } from '../brighteon/common';

start();

function loaded() {
  const AccountActionContainer = require('../brighteon/containers/account_action_container').default;
  const AccountTipContainer    = require('../brighteon/containers/account_tip_container').default;
  const React = require('react');
  const ReactDOM = require('react-dom');
  const actionMountNode = document.getElementById('brighteon-account-action');
  const cryptoMountNode = document.getElementById('brighteon-account-tip-crypto');

  if (actionMountNode !== null) {
    const props = JSON.parse(actionMountNode.getAttribute('data-props'));
    const accountId = actionMountNode.getAttribute('data-account');

    ReactDOM.render(<AccountActionContainer {...props} accountId={accountId} />, actionMountNode);
  }

  if (cryptoMountNode !== null) {
    const accountId   = cryptoMountNode.getAttribute('data-account-id');
    const accountName = cryptoMountNode.getAttribute('data-account-name');

    ReactDOM.render(<AccountTipContainer accountName={accountName} accountId={accountId} />, cryptoMountNode);
  }
}

function main() {
  const ready = require('../brighteon/ready').default;
  ready(loaded);
}

loadPolyfills().then(main).catch(error => {
  console.error(error);
});
