import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import configureStore from '../store/configureStore';
import { hydrateStore } from '../actions/store';
import { IntlProvider, addLocaleData } from 'react-intl';
import { getLocale } from '../locales';
import AccountTip from '../components/account_tip_container';
import initialState from '../initial_state';
import { fetchCustomEmojis } from '../actions/custom_emojis';
import ModalContainer from '../features/ui/containers/modal_container';

const { localeData, messages } = getLocale();
addLocaleData(localeData);

const store = configureStore();

if (initialState) {
  store.dispatch(hydrateStore(initialState));
}

store.dispatch(fetchCustomEmojis());

export default class AccountTipContainer extends React.PureComponent {

  static propTypes = {
    locale: PropTypes.string,
    accountId: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired,
  };

  render () {
    const { locale, accountId, accountName } = this.props;

    return (
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <AccountTip accountId={accountId} accountName={accountName} />
          {
            ReactDOM.createPortal(
              <ModalContainer />,
              document.getElementById('modal-container'),
            )
          }
        </Provider>
      </IntlProvider>
    );
  }

}
